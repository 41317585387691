<template>
  <v-container>
    <v-row class="pb-10 grey--text text--darken-1 display-1">
      <v-col cols="12" md="5"> {{ $t("task_table.info") }}</v-col
      ><v-col cols="12" md="5">
        <download-excel
          class="btn"
          :fetch="downloadAllData"
          :fields="json_fields"
          worksheet="My Worksheet"
          name="vsetky_tasky.xls"
        >
          <v-btn color="primary">
            {{ $t("task_table.download_all_meetings") }}
          </v-btn>
        </download-excel>
      </v-col>
    </v-row>
    <v-alert v-show="errorAxios" class="mb-6" type="error">
      {{ $t("meeting_table.error_connection") }}
    </v-alert>

    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.4)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="60"
            :width="7"
            color="primary"
          >
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="tasks"
      item-key="id"
      class="elevation-1"
      @click:row="clickTableRow"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:[`item.name`]="{ value }">
        <div class="frame" style="height: 50px; overflow-y: scroll">
          <div :inner-html.prop="value"></div>
        </div>
      </template>

      <template v-slot:top>
        <v-row class="text-right">
          <v-col cols="12" md="5">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-find-replace"
              :label="$t('task_table.search')"
              class="ma-0 ml-md-4"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectWhoResponsible"
              :items="itemWhoResponsible"
              :label="$t('task_table.filter_who_responsible')"
              multiple
              chips
              class="ma-0 mr-md-4"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectOvm"
              :items="itemOvm"
              :label="$t('task_table.filter_ovm')"
              multiple
              chips
              class="ma-0 ml-md-4"
            ></v-combobox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectStatus"
              :items="itemStatus"
              :label="$t('task_table.filter_status')"
              multiple
              chips
              class="ma-0 mr-md-4"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectUID"
              :items="itemUID"
              :label="$t('task_table.filter_UID')"
              multiple
              chips
              class="ma-0 ml-md-4"
            ></v-combobox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectProject"
              :items="itemProject"
              :label="$t('meeting_table.filter_project')"
              chips
              class="ma-0 mr-md-4"
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectTaskType"
              :items="itemTaskType"
              :label="$t('task_table.filter_TaskType')"
              multiple
              chips
              class="ma-0 ml-md-4"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row class="ma-0 ml-md-1">
          <v-col cols="12" sm="6" md="3">
            <v-menu
              ref="menuDateBegin"
              v-model="menuDateBegin"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateBegin"
                  :label="$t('task_table.date_begin')"
                  prepend-icon="mdi-calendar"
                  readonly
                  @click:clear="dateBegin = null"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                v-model="dateBegin"
                locale="sk-SK"
                no-title
                scrollable
                @change="menuDateBegin = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-menu
              ref="menuDateEnd"
              v-model="menuDateEnd"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateEnd"
                  :label="$t('task_table.date_end')"
                  prepend-icon="mdi-calendar"
                  readonly
                  @click:clear="dateEnd = null"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                v-model="dateEnd"
                locale="sk-SK"
                no-title
                scrollable
                @change="menuDateEnd = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" md="6" class="text-center">
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="ma-0" @click="resetFilter()">
              {{ $t("task_table.reset") }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <v-toolbar flat>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{
                $t("task_table.delete")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{
                  $t("task_table.cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                  $t("task_table.ok")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-show="editable(item.id)"
              small
              v-bind="attrs"
              v-on="on"
              fap
              class="ma-0 mr-md-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $t("task_table.editTooltip") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>




<script>
import axios from "axios";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { mapGetters } from "vuex";
import { VueOfflineStorage } from "vue-offline";

Vue.component("downloadExcel", JsonExcel);
export default {
  data: () => ({
    id_token: "",
    json_fields: {
      ID: "uid",
      "Typ záveru": "taskType.name",
      "Popis záveru": "name",
      "Názov stretnutia": "meetingTopic",
      Status: "status",
      "Názov OVM": "ovmName",
      Projekt: "project.name",
      Termín: "deadline",
      Zodpovedný: "responsible",
      Komentár: "comment",
    },
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    prepare_download: true,
    loading: true,
    actualLoading: 0,
    tasks: [],
    errorAxios: false,
    deletingFile: false,
    dialog: false,
    search: "",
    dialogDelete: false,
    itemUID: [],
    itemProjectObj: [],
    itemProject: [],
    itemTaskTypeObj: [],
    itemTaskType: [],
    selectUID: "",
    selectProject: "",
    selectTaskType: "",

    menuDateBegin: false,
    menuDateEnd: false,
    dateBegin: null,
    dateEnd: null,

    admin: false,
    editMeetings: [],
    editTasks: [],

    itemName: [],
    itemOvm: [],
    itemWhoResponsible: [],
    itemStatus: [],

    selectName: "",
    selectOvm: "",
    selectTaskType: "",
    selectStatus: "",
    selectWhoResponsible: "",

    editedIndex: -1,
    editedItem: {
      uid: "",
      name: "",
      ovm: "",
      who_responsible: "",
      status: "",
      date: "",
    },
    defaultItem: {
      uid: "",
      name: "",
      ovm: "",
      who_responsible: "",
      status: "",
      date: "",
    },
  }),

  computed: {
    ...mapGetters(["isLoggedIn", "getToken"]),
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "uid",
          filter: (value) => {
            let acronyms = [""];
            // let helpValue = ""
            let beforeDots = value.split(".")[0];
            let helpValue = beforeDots.replace(/[0-9]/g, "");
            if (this.selectProject != "" && this.selectProject != undefined) {
              acronyms = [
                ...new Set(
                  this.tasks
                    .filter((p) => p.project.name.includes(this.selectProject))
                    .map(function (item) {
                      return item["project"]["acronym"];
                    })
                ),
              ];
              if (acronyms.length == 0) return false;
            }
            let isProject = true;
            if (acronyms[0] != "") {
              isProject = acronyms.includes(helpValue);
            }
            if (!this.selectUID) return isProject;
            if (this.selectUID.length == 0) return isProject;
            return this.selectUID.includes(value) & isProject;
          },
        },
        {
          text: "Typ",
          sortable: true,
          value: "taskType.name",
          filter: (value) => {
            if (!this.selectTaskType) return true;
            if (this.selectTaskType.length == 0) return true;
            return this.selectTaskType.includes(value);
          },
        },
        {
          text: "Popis",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "OVM",
          value: "ovmName",
          filter: (value) => {
            if (!this.selectOvm) return true;
            if (this.selectOvm.length == 0) return true;
            return this.selectOvm.includes(value);
          },
        },
        {
          text: "Zodpovedný",
          value: "personName",
          filter: (value) => {
            if (!this.selectWhoResponsible) return true;
            if (this.selectWhoResponsible.length == 0) return true;
            return this.selectWhoResponsible.includes(value);
          },
        },
        {
          text: "Stav",
          value: "status.name",
          filter: (value) => {
            if (!this.selectStatus) return true;
            if (this.selectStatus.length == 0) return true;
            return this.selectStatus.includes(value);
          },
        },
        {
          text: "Termín",
          value: "deadline",
          filter: (value) => {
            let parseEnd = null;
            let parseBegin = null;

            if (value == null) {
              return true;
            }

            if (this.dateBegin) {
              parseBegin = parseInt(this.dateBegin.replaceAll("-", ""));
            }
            if (this.dateEnd) {
              parseEnd = parseInt(this.dateEnd.replaceAll("-", ""));
            }
            let parseValue = parseInt(value.replaceAll("-", ""));
            if (!parseBegin && !parseEnd) {
              return true;
            } else if (!parseBegin && parseEnd >= parseValue) {
              return true;
            } else if (!parseEnd && parseBegin <= parseValue) {
              return true;
            } else if (parseEnd >= parseValue && parseBegin <= parseValue) {
              return true;
            } else {
              return false;
            }
          },
        },
        { text: "Akcie", value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.actualLoading = 0;
    this.loadData();
  },

  methods: {
    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response.status == 403) this.$router.push("login").catch(() => {});
      this.completeLoadingBlock();
    },

    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    async loadData() {
      this.id_token = this.getToken;
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();

      await this.loadEditingApproval();

      await Promise.all([this.loadProject(), this.loadTaskType()]).then(
        (values) => {
          this.initialize();
        }
      );

      // await this.loadProject();
      // await this.loadTaskType();
      // await this.initialize();
    },

    saveOfflineMode(key, value) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        VueOfflineStorage.set(key, value);
      }
    },

    async downloadAllData() {
      this.setLoadingBlock();
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_ALL_TASK, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          let alltasks = response.data;

          alltasks.forEach(
            (element) =>
              (element.uid =
                element.project.acronym + element.meetingId + "." + element.uid)
          );
          alltasks.map((item) => {
            if (item.deadline) {
              item.deadline = item.deadline.substring(0, 10);
            }
          });

          alltasks.map((item) => {
            if (item.status) {
              item.status = item.status.name;
            }
          });

          alltasks.map((item) => {
            item.responsible = "";
            if (item.person) {
              item.responsible =
                item.person.name +
                ", " +
                item.person.position +
                ", " +
                item.person.ovm.name;
            }
          });

          this.json_data = alltasks;
          this.prepare_download = false;
          this.completeLoadingBlock();
          return this.json_data;
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    editable(id) {
      if (this.admin == true) return true;
      return this.editTasks.includes(id);
    },

    loadEditingApproval() {
      return axios
        .get(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_GET_EDITING_APPROVAL,
          { headers: { Authorization: `Bearer ${this.id_token}` } }
        )
        .then((response) => {
          this.admin = response.data.admin;
          this.editMeetings = response.data.meetings;
          this.editTasks = response.data.tasks;
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadTaskType() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_TASKTYPE, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemTaskTypeObj = response.data;

          this.itemTaskType = [
            ...new Set(this.itemTaskTypeObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("taskTypes", response.data);
          this.completeLoadingBlock();
          this.itemTaskType.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadProject() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PROJECT, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemProjectObj = response.data;

          this.itemProject = [
            ...new Set(this.itemProjectObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("projects", response.data);
          this.errorAxios = false;
          this.completeLoadingBlock();
          this.itemProject.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    newTask() {
      this.$router.push("task/create");
    },
    resetFilter() {
      this.dateEnd = "";
      this.dateBegin = "";
      this.search = "";
      this.selectStatus = "";
      (this.selectUID = ""), (this.selectProject = "");
      this.selectTaskType = "";
      this.selectOvm = "";
      this.selectTaskType = "";
      this.selectWhoResponsible = "";
    },
    setupFilters() {
      this.itemUID = [...new Set(this.tasks.map((item) => item.uid))];
      this.itemTopic = [...new Set(this.tasks.map((item) => item.name))];
      this.itemOvm = [...new Set(this.tasks.map((item) => item.ovmName))];
      this.itemWhoResponsible = [
        ...new Set(this.tasks.map((item) => item.personName)),
      ];
      this.itemStatus = [
        ...new Set(this.tasks.map((item) => item.status.name)),
      ];
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toString().toLocaleUpperCase()) !== -1
      );
    },
    initialize() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_TASK, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.tasks = response.data;
          this.tasks = this.tasks.map((item) => {
            if (item.status == null) item.status = { name: "" };
            if (item.personId == null) item.personId = "";
            if (item.personName == null) item.personName = "";
            item.uid = item.project.acronym + item.meetingId + "." + item.uid;

            if (item.deadline == null) item.deadline = "";
            else item.deadline = item.deadline.substring(0, 10);
            return item;
          });
          this.setupFilters();
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    editItem(item) {
      this.$router.push("task/edit/" + item.id);
    },

    deleteItem(item) {
      this.deletingFile = true;
      this.editedIndex = this.tasks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.tasks.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    clickTableRow(index) {
      if (this.deletingFile != true) {
        this.$router.push("task/show/" + index.id);
      }
      this.deletingFile = false;
    },
  },
};
</script>

<style scoped>
.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
