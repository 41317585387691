<template>
  <div>
    <NavBar />
    <table-of-tasks />
  </div>
</template>


<script>
import NavBar from "../components/NavBar";
import TableOfTasks from "../components/TableOfTasks";

export default {
  name: "MeetingsTable",

  components: {
    NavBar,
    TableOfTasks,
  },
};
</script>
